<template>
  <!-- 新闻详情页 -->
  <div class="news-detail">
    <h1>{{ newsData.title }}</h1>

    <p class="time">发布日期：{{ newsData.createdAt | dateFormat }}</p>

    <pre v-html="newsData.content"></pre>

    <!-- <p class="img-info">(图片说明)</p> -->

    <div class="foot-nav">
      <div>
        上一篇：
        <el-link
          @click="toViewOther(prevNews._links.self.href)"
          v-if="prevNews"
          >{{ prevNews.title }}</el-link
        >
        <span v-else>暂无文章</span>
      </div>
      <div>
        下一篇：
        <el-link
          @click="toViewOther(nextNews._links.self.href)"
          v-if="nextNews"
          >{{ nextNews.title }}</el-link
        >
        <span v-else>暂无文章</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsById, getNext, getPrev, publishNews } from "@/api/news";

export default {
  data() {
    return {
      newsData: {}, // 新闻数据
      prevNews: "", // 相邻新闻(上)
      nextNews: "", // 相邻新闻(下)
      news: {
        state: "PUBLISHED",
        type: 1,
        content: "",
        title: "",
      },
    };
  },
  created() {
    this.getNewsById(); // 获取新闻
  },
  methods: {
    getNewsById() {
      // 获取当前新闻
      getNewsById(this.$route.params.id).then((res) => {
        this.newsData = res.data;
      });

      // 获取下一条新闻
      getPrev(this.$route.params.id)
        .then((res) => {
          this.prevNews = res.data;
        })
        .catch((err) => {
          this.prevNews = null;
        });

      // 获取前一条新闻
      getNext(this.$route.params.id)
        .then((res) => {
          this.nextNews = res.data;
        })
        .catch((err) => {
          this.nextNews = null;
        });
    }, // 根据id获取新闻

    toViewOther(href) {
      let id = href.split("/").pop();
      this.$router.push(`/news/view/${id}`);
    }, // 查看上下新闻
  },
  beforeRouteUpdate(to, from, next) {
    console.log("新闻id更新", { to, from });
    next();
    this.getNewsById();
  }, // 路由守卫id更新
};
</script>

<style lang="less" scoped>
.news-detail {
  width: 780px;

  h1 {
    font-size: 20px;
    font-weight: bold;
    color: var(--main-title_black);
  }

  .time {
    margin: 16px 0 24px;
    color: var(--sub-title_gray);
  }

  pre {
    // 兼容多个浏览器
    line-height: 24px;
    text-align: justify;
    margin-bottom: 0;
    color: var(--main-memo_gray);
    font-family: "Source Han Sans CN";
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    *word-wrap: break-word;
    *white-space: normal;
  }

  img {
    width: 780px;
    height: 438px;
    object-fit: cover;
  }

  .img-info {
    margin-top: 16px;
    color: var(--main-memo_gray);
    height: 56px;
  }

  .foot-nav {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;

    a {
      color: #4091f7;
    }
  }
}
</style>
